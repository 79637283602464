/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'jquery/dist/jquery';
// import 'slick-carousel/slick/slick';

// Support component names relative to this directory:

import ReactOnRails from 'react-on-rails'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'swiper/css'
import 'swiper/css/mousewheel'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'

import { SearchForm } from '~/organisms/search/SearchForm'
import '@/config/i18n/config'

registerLocale('ja', ja)

ReactOnRails.register({
  SearchForm,
})
